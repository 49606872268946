<template>
  <div>
    <h1>Fetch SOL token holders</h1>
    <p>Fetch all holders of a token</p>
    <form>
      <label for="tokenAddress">Token Address</label>
      <input class="form-text" v-model="tokenAddress"
             placeholder="Example: 43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We">

      <label for="minHoldingAmount">Min holding amount</label>
      <input class="form-text" v-model="minHoldingAmount" placeholder="Example: 150">
    </form>
    <button class="btn btn-primary" v-on:click="retrieveAllholders">Retrieve data</button>

    <br>
    <hr>
    <label for="allHolders">All Holders</label><br>
    <label for="">Amount holders: {{ holdersAmount }}</label>
    <br>
    <textarea v-model="allHoldersData" placeholder="This text area will list all holders" cols="84"
              rows="90"></textarea><br>
    <label for="">Download all addresses</label><br>
    <!--    <button class="btn btn-primary">Download CSV</button>-->
    <button class="btn btn-primary">
      <download-csv
          :data="filteredHolders">
        Download Data
      </download-csv>
    </button>
  </div>
</template>

<script>
import {Connection, PublicKey} from '@solana/web3.js';

let connection = new Connection('https://api.mainnet-beta.solana.com/');

export default {
  name: "solHolders",
  props: {
    msg: String
  },
  data() {
    return {
      tokenAddress: '',
      minHoldingAmount: 1,
      tokenHolders: [],
      allHoldersData: '',
      holdersAmount: 0,
      filteredHolders: [],
    };
  },
  methods: {
    retrieveAllholders() {
      this.clearAllInputData();
      if (this.tokenAddress == '') {
        alert('Fill in the token address');
        return;
      }
      this.allHoldersData = 'loading...';
      this.getCurrentHolders().then(() => {
        console.log('All holders array data: ', this.tokenHolders)

        this.allHoldersData = '';
        let i = 0;
        this.tokenHolders.forEach(element => {
          if (element.account.data.parsed.info.tokenAmount.uiAmount > this.minHoldingAmount) {
            this.filteredHolders.push({
              'address': element.pubkey.toBase58(),
              'Amount': element.account.data.parsed.info.tokenAmount.uiAmount
            })
            this.allHoldersData += 'Owner: ' + element.pubkey.toBase58() + ' \n';
            this.allHoldersData += 'Amount: ' + element.account.data.parsed.info.tokenAmount.uiAmount + ' \n\n';
            i++;
          }
        });

        this.holdersAmount = i;

        // console.log('Holders amount', i);
        // console.log('Holders all data string: ', this.allHoldersData);
        // console.log('Token holders log:', this.tokenHolders);
      });
    },
    async getCurrentHolders() {
      let tokenProgramPublicKey = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');
      let memCmpFilter = {memcmp: {bytes: this.tokenAddress, offset: 0}}
      this.tokenHolders = await connection.getParsedProgramAccounts(tokenProgramPublicKey, {
        commitment: 'confirmed',
        encoding: 'jsonParsed',
        filters: [{dataSize: 165}, memCmpFilter]
      });
    },
    clearAllInputData() {
      this.allHoldersData = '';
      this.holdersAmount = 0;
      this.filteredHolders = [];
    }
  }
}
</script>
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  watch: {
    '$route':{
      handler: (to) => {
        document.title = to.meta.title || ''
      },
      immediate: true
    }
  },
}


</script>

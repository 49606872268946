import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import routes from "@/routes";
import BootstrapVue from 'bootstrap-vue'
import '@/assets/style/style.scss';
import JsonCSV from "vue-json-csv";

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.component('downloadCsv', JsonCSV)

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')

<template>
  <div>
    <div class="stocks-anim-bg">
      <div class="white-side-landing"></div>
      <div class="container head-landing">
        <div class="row">
          <div class="col-md-6">
            <h1>STONKS</h1>
            <p>The coin for<br> real
              <del>DeFi</del>
              stonk investors
            </p>
          </div>
          <div class="col-md-6">

            <img class="stonks-landing" src="@/assets/images/hehe.png" alt="">
            <img class="arrow-landing" src="@/assets/images/arrow.png" alt="">

          </div>

        </div>
      </div>
    </div>
    <div class="brands-strip">
      <div class="container">
        <div class="row">
          <div class="col"><img src="@/assets/images/FYU.png" alt=""></div>
          <div class="col"><img src="@/assets/images/forbs.png" alt=""></div>
          <div class="col"><img src="@/assets/images/Micosof.png" alt=""></div>
          <div class="col"><img src="@/assets/images/binan.png" alt=""></div>
        </div>
        <small>*These companies don't exist</small>
      </div>
    </div>

    <div>
      <div class="container about">
        <div class="row">
          <div class="col">
            <h2>STONKS is a meme coin
              running on the Solana ecosystem
            </h2>
            <p>
              A lot of DeFi applications are run on Etheurem. Now with more people than ever using DeFi, it became
              almost impossible to run on the ETH network, due to scalability issues. Solana comes to the rescue.
              Transactions are blazing fast and cheap. STONKS is a governance token made on the Solana network to spread
              awareness about the usability, and performance of Solana. It is made to encourage future developers to
              launch their initiatives on the Solana ecosystem.
            </p>
          </div>
          <div class="col">
            <img src="@/assets/images/solana.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="container white-paper-buy">
        <div class="row">
          <div class="col-md-6">
            <div class="read-whitepaper">
              <div class="row">
                <div class="col left-click-whitepaper" @mouseover="hoverRead = true" @mouseleave="hoverRead = false">
                  <h3>Read Witepapor</h3>
                  <p>Read our witepapor (Whitepaper) to get informed about our project and goals.</p>
                  <!--                  <a href="/pdf/StonksWitepapor.pdf" target="_blank" class="btn btn-primary">Open PDF</a>-->
                  <a href="/pdf/StonksWitepapor.pdf" target="_blank"
                     class="btn btn-primary custom-btn">Open
                    PDF</a>
                </div>
                <div class="col">
                  <div class="right-whitepaper" :class="{ hoverChangeBg: hoverRead }"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="read-buy">
              <div class="row">
                <div class="col how-to-buy" @mouseover="hoverBuy = true" @mouseleave="hoverBuy = false">
                  <h3>How To Buy STNK</h3>
                  <p>Learn how to buy STNK on the following Solana Serum dex.</p>
                  <br>
                  <div class="row row-cols-sm-6 gy-5">
                    <div class="col">
                      <img @mousedown="showModal" class="dex-icon" src="@/assets/images/raydium.jpg" alt="Raydium"
                           title="Raydium">
                    </div>
                    <div class="col">
                      <img @mousedown="showModal" class="dex-icon" src="@/assets/images/serum.png" alt="Serum"
                           title="Serum">
                    </div>
                    <div class="col">
                      <img @mousedown="showModal" class="dex-icon" src="@/assets/images/bonfida.png" alt="Bonfida"
                           title="Bonfida">
                    </div>
                    <div class="col">
                      <a target="_blank"
                         href="https://solapeswap.io/#/market/7vJhxNnkPBTJKNHsbjZUhmCVCxmYKgV6vgJ56eH2MQaC">
                        <img class="dex-icon" src="@/assets/images/solape.png" alt="SolApe" title="SolApe">
                      </a>
                    </div>
                  </div>
                  <a href="https://solapeswap.io/#/market/7vJhxNnkPBTJKNHsbjZUhmCVCxmYKgV6vgJ56eH2MQaC" target="_blank"
                     class="btn btn-primary custom-btn">Buy STNK</a>
                </div>
                <div class="col right-how-to-buy" :class="{ hoverChangeBg: hoverBuy }">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="story-bg">
      <div class="container story">
        <h1>What are we working on?</h1>
        <p>As more applications of DeFi services arise, tracking of DeFi assets has become a necessity. Different
          innovative DeFi applications have been proposed, that aim to reach a wide audience. However, in order to do
          so, it must be easily accessible. This is often not the case. Current portfolio tracking applications are not
          user friendly and interfaces often lack intuitiveness. We try to solve this, by making an intuitive portfolio
          tracker: DeFinans. Definans tries to build the bridge between DeFi and mass adoption by introducing a robust
          tracker. As often, developers do not put user experience as a priority.</p>
      </div>
    </div>

    <div>
      <div class="container follow">
        <div class="row">

          <div class="col">
            <h2>Follow us on!</h2>
            <a href="https://twitter.com/STONKS_SOL" target="_blank" class="social twitter p-3">
              <img src="@/assets/images/twitter.png" alt="">
            </a>
            <a href="https://discord.gg/5cJDeRD72Z" target="_blank" class="social discord p-3">
              <img src="@/assets/images/discord.svg" alt="">
            </a>
          </div>
          <div class="col">
            <a href="https://definans.net/">
              <img width="400" src="@/assets/images/Definans.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>

    <learnBuyModal
        v-show="isModalVisible"
        @close="closeModal"
    >
      <template v-slot:header>
        How To Buy & Sell STNK On Serum, Bonfida or Raydium?
      </template>

      <template v-slot:body>
        <h5 class="font-weight-bold">Step 1: Add custom market by clicking on the "+" Sign</h5>
        <img src="@/assets/images/addPLus.png" class="rounded mx-auto d-block howToBuyImg" alt="Responsive image">
        <br>
        <h5 class="font-weight-bold">Step 2: Add STNK market using the following details</h5>
        <br>
        <div class="form-group row">
          <label for="marketID" class="col-sm-2 col-form-label">MarketID</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control" id="marketID"
                   value="7vJhxNnkPBTJKNHsbjZUhmCVCxmYKgV6vgJ56eH2MQaC">
          </div>
        </div>
        <div class="form-group row">
          <label for="markLabel" class="col-sm-2 col-form-label">Market Label</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control" value="STNK/USDC" id="markLabel">
          </div>
        </div>
        <div class="form-group row">
          <label for="basLabel" class="col-sm-2 col-form-label">Base Label</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control" value="STONKS" id="basLabel">
          </div>
        </div>

        <h5 class="font-weight-bold">Go to market</h5>
        <div class="row row-cols-sm-6 gy-6 icon-list-go-market">
          <div class="col">
            <a target="_blank" href="https://dex.raydium.io/#/market/7vJhxNnkPBTJKNHsbjZUhmCVCxmYKgV6vgJ56eH2MQaC">
              <img class="dex-icon" src="@/assets/images/raydium.jpg" alt="Raydium" title="Raydium">
            </a>
          </div>
          <div class="col">
            <a target="_blank"
               href="https://dex.projectserum.com/#/market/7vJhxNnkPBTJKNHsbjZUhmCVCxmYKgV6vgJ56eH2MQaC">
              <img class="dex-icon" src="@/assets/images/serum.png" alt="Serum" title="Serum">
            </a>
          </div>
          <div class="col">
            <a target="_blank" href="https://dex.bonfida.com/#/market/EbV7pPpEvheLizuYX3gUCvWM8iySbSRAhu2mQ5Vz2Mxf">
              <img class="dex-icon" src="@/assets/images/bonfida.png" alt="Bonfida" title="Bonfida">
            </a>
          </div>
        </div>
      </template>

    </learnBuyModal>
  </div>


</template>

<script>
import learnBuyModal from './modals/learnBuyModal';

export default {
  name: 'Stonks',
  components: {
    learnBuyModal,
  },
  props: {
    msg: String
  },
  data() {
    return {
      hoverBuy: false,
      hoverRead: false,
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
}
</script>

import About from "@/components/About";
import Home from "@/components/Home";


export default [
    {
        path: '/solHolders',
        name: 'solHolders',
        component: About,
        meta: {
            title: 'solHolders'
        }
    },
    {
        path: '/',
        name: 'Stonks',
        component: Home,
        meta: {
            title: 'Stonks'
        }
    },
    {
        path: '/*',
        name: 'Stonks',
        component: Home,
        meta: {
            title: 'Stonks'
        }
    },
];